.h4 {
  font-size: 30px;
  font-family: "Hacen Algeria Bd";
  margin-bottom: 40px !important;
  color: var(--dark-red-color);
}
@media (min-width: 992px) {
  .h4 {
    font-size: 70px;
  }
}

.p {
  font-size: 15px;
  font-family: "Hacen Algeria Bd";
}
@media (min-width: 992px) {
  .p {
    font-size: 30px;
  }
}

.ul {
  font-size: 13px;
}
@media (min-width: 992px) {
  .ul {
    font-size: 22px;
  }
}

.li {
  list-style-image: url("../assets/images/Rectangle\ 10220.svg");
  padding-left: 18px;
  margin-bottom: 16px;
}
@media (min-width: 992px) {
  .li {
    padding-left: 30px;
  }
}

.p2 {
  font-size: 15px;
  margin-bottom: 16px !important;
  margin-left: 16px;
}
@media (min-width: 992px) {
  .p2 {
    font-size: 25px;
  }
}

.button {
  background: var(--dark-black-color) !important;
  width: 159px;
  height: 48px;
  font-size: 12px;
  border-radius: 3px;
  margin-left: 16px;
}
@media (min-width: 992px) {
  .button {
    width: 261px;
    height: 78px;
  }
}

.file_icon {
  width: 12px;
  height: 16px;
}
@media (min-width: 992px) {
  .file_icon {
    width: 17px;
    height: 23px;
  }
}

.span {
  font-size: 13px;
}
@media (min-width: 992px) {
  .span {
    font-size: 21px;
  }
}

.mockups {
  width: 100%;
  object-fit: contain;
}

.rectangle {
  width: 80%;
  height: 140px;
  position: absolute;
  right: 0px;
  bottom: 6%;
  z-index: -1;
}

@media (min-width: 768px) {
  .rectangle {
    height: 200px;
  }
}

@media (min-width: 1200px) {
  .rectangle {
    width: 40%;
    bottom: 31%;
  }
}
@media (min-width: 1400px) {
  .rectangle {
    bottom: 26%;
  }
}

.aside {
  background: transparent
    url("../assets/images/Mask\ Group\ 14/Mask Group 14.png") 60% 0% no-repeat
    padding-box;
  max-width: 1296px;
}
@media (min-width: 1289px) {
  .aside {
    background: transparent
      url("../assets/images/Mask\ Group\ 14/Mask Group 14.png") 0% 0% no-repeat
      padding-box;
  }
}

.row3 {
  background: var(--dark-black-color);
  z-index: -1;
}

.h4_3 {
  font-family: "Hacen Algeria Bd";
  font-size: 35px;
}

.p_3 {
  width: 252px;
}
@media (min-width: 992px) {
  .p_3 {
    width: 590px;
    font-size: 28px;
  }
}

.mark {
  width: 45px;
  height: 45px;
  transform: matrix(0.94, -0.34, 0.34, 0.94, 0, 0);
  margin-bottom: 16px;
}
@media (min-width: 992px) {
  .mark {
    width: 85px;
    height: 85px;
  }
}
