.bg {
  background: transparent
    url("../assets/images/Srena website - 1/Group 18959@2x.png") 70% 60%
    no-repeat padding-box;
}
@media (min-width: 768px) {
  .bg {
    background: transparent
      url("../assets/images/Srena website - 1/Group 18959@2x.png") 65% 0%
      no-repeat padding-box;
  }
}
@media (min-width: 1440px) {
  .bg {
    background: transparent
      url("../assets/images/Srena website - 1/Group 18959@2x.png") 60% 80%
      no-repeat padding-box;
  }
}

@media (min-width: 1440px) {
  .h3 {
    font-size: 60px;
  }
}

.hi_icon {
  width: 69px;
  height: 70px;
}
@media (min-width: 1440px) {
  .hi_icon {
    width: 138px;
    height: 138px;
  }
}

.p1 {
  font-size: 20px;
  max-width: 596px;
}
@media (min-width: 1440px) {
  .p1 {
    font-size: 30px;
  }
}

.p2 {
  font-size: 22px;
}
@media (min-width: 1440px) {
  .p2 {
    font-size: 35px;
  }
}

.button {
  background: var(--dark-black-color) !important;
  width: 159px;
  height: 48px;
  font-size: 12px;
  border-radius: 3px;
}
@media (min-width: 1440px) {
  .button {
    width: 261px;
    height: 78px;
  }
}

.icon {
  width: 20px;
  height: 24px;
}

.span {
  font-size: 12px;
}
@media (min-width: 1440px) {
  .span {
    font-size: 21px;
  }
}
