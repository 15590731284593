.container {
  bottom: 10px;
  right: 8px;
  cursor: pointer;
  z-index: 99;
}
@media (min-width: 768px) {
  .container {
    bottom: 10px;
    right: 8px;
  }
}

.arrow {
  width: 7px;
  height: 11px;
}
@media (min-width: 768px) {
  .arrow {
    width: 12px;
    height: 17px;
  }
}

.top {
  font-size: 8px;
  color: var(--dark-red-color);
}
@media (min-width: 768px) {
  .top {
    font-size: 15px;
  }
}
