.col {
  background: var(--dark-black-color);
}

.h6_1 {
  font-family: "Hacen Algeria Bd";
  font-size: 30px;
}
@media (min-width: 768px) {
  .h6_1 {
    font-size: 50px;
  }
}

.p1 {
  font-size: 15px;
}
@media (min-width: 768px) {
  .p1 {
    font-size: 22px;
  }
}

.icon {
  width: 26px;
  height: 26px;
}

.p2 {
  font-size: 16px;
  margin: 0;
}
@media (min-width: 768px) {
  .p2 {
    font-size: 26px;
  }
}

.p3 {
  font-size: 12px;
}
@media (min-width: 768px) {
  .p3 {
    font-size: 20px;
  }
}

.social_icon {
  width: 30px;
  height: 30px;
}
@media (min-width: 768px) {
  .social_icon {
    width: 46px;
    height: 46px;
  }
}

.image_container {
  height: 100%;
  background-image: url("../assets/images/photo-1553775282-20af80779df7.avif");
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 353px;
}
@media (min-width: 768px) {
  .image_container {
    min-height: 634px;
  }
}

/* .wrapper_stack {
  position: relative;
  margin-top: -303px;
  z-index: 10;
}
@media (min-width: 768px) {
  .wrapper_stack {
    margin-top: -393px;
  }
} */

/* .form {
  background: var(--dark-black-color);
  width: 294px;
  height: 380px;
}
@media (min-width: 768px) {
  .form {
    width: 560px;
    min-height: 480px;
    max-width: 560px;
  }
} */

/* .h6_2 {
  font-family: "Hacen Algeria Bd";
  font-size: 20px;
  padding: 23px 0;
}
@media (min-width: 768px) {
  .h6_2 {
    font-size: 29px;
  }
} */

/* input,
textarea {
  background: transparent;
  border: none;
  border-bottom: 0.5px solid #ffffff;
} */

/* textarea {
  margin-top: 30px;
} */

/* .input {
  font-size: 10px;
  width: 100%;
  color: #ffffff;
  min-height: 50px;
}
@media (min-width: 768px) {
  .input {
    font-size: 17px;
  }
} */

/* .button {
  background: var(--dark-red-color) !important;
  color: #ffffff;
  width: 161px;
  height: 40px;
  font-size: 15px;
  border-radius: 3px;
  border: none;
}
@media (min-width: 768px) {
  .button {
    width: 213px;
    height: 58px;
    font-size: 20px;
  }
} */

.p4 {
  font-size: 10px;
}
@media (min-width: 768px) {
  .p4 {
    font-size: 22px;
  }
}
