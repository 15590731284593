* {
  box-sizing: border-box !important;
  margin: 0;
  padding: 0;
  text-decoration: none;
}

:root {
  --dark-black-color: #0e2129;
  --light-pink-color: #fcf3f4;
  --dark-red-color: #b5153a;
}

@font-face {
  font-family: "Hacen Algeria";
  src: url("./assets/fonts/Hacen-Algeria.ttf");
}

@font-face {
  font-family: "Hacen Algeria Bd";
  src: url("./assets/fonts/Hacen-Algeria-Bd.ttf");
}

@font-face {
  font-family: "Monteserrat";
  src: url("./assets/fonts/Montserrat-Arabic-SemiBold.ttf");
}

body,
html {
  font-family: "Hacen Algeria" !important;
  color: var(--dark-black-color) !important;
  overflow-x: hidden !important;
}
a {
  text-decoration: none;
}

.carousel-control-prev,
.carousel-control-next,
.carousel-indicators {
  display: none;
}
