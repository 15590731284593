.title {
  font-family: "Hacen Algeria Bd";
  font-size: 30px;
  text-align: center;
}

.h4 {
  font-family: "Hacen Algeria Bd";
  font-size: 15px;
}
@media (min-width: 768px) {
  .h4 {
    font-size: 25px;
  }
}

.client_image {
  width: 71px;
  height: 56px;
  width: 100%;
  object-fit: contain;
}
@media (min-width: 992px) {
  .client_image {
    width: 141px;
    height: 112px;
  }
}

.p1 {
  font-size: 13px;
}
@media (min-width: 768px) {
  .p1 {
    font-size: 22px;
  }
}

@media (min-width: 768px) {
  .p2 {
    font-size: 25px;
  }
}

.control_icon {
  width: 13px;
  height: 9px;
  cursor: pointer;
}
@media (min-width: 768px) {
  .control_icon {
    width: 28px;
    height: 19px;
  }
}

.number_text {
  font: normal normal normal 8px/9px "Hacen Algeria Bd";
}
@media (min-width: 768px) {
  .number_text {
    font: normal normal normal 22px/24px "Hacen Algeria Bd";
  }
}

.span {
  font: normal normal 600 11px/21px "Montserrat";
}
@media (min-width: 768px) {
  .span {
    font: normal normal 600 30px/59px "Montserrat";
  }
}

.join_now {
  background: #fcf3f4;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 43px 0;
  gap: 50px;
  text-align: center;
}

.h5 {
  font-family: "Hacen Algeria Bd";
  font-size: 20px;
  margin-bottom: 8px !important;
}
@media (min-width: 768px) {
  .h5 {
    font-size: 30px;
  }
}

.p3 {
  font-size: 15px;
}
@media (min-width: 768px) {
  .p3 {
    font-size: 22px;
  }
}

.button {
  background: var(--dark-black-color) !important;
  width: 159px;
  height: 48px;
  font-size: 15px;
  border-radius: 3px;
}
@media (min-width: 1440px) {
  .button {
    width: 213px;
    height: 58px;
    font-size: 20px;
  }
}
