.logo {
  width: 125px;
  height: 28px;
}
@media (min-width: 768px) {
  .logo {
    width: 192px;
    height: 43px;
  }
}

.icon {
  width: 15px;
  height: 18px;
}
@media (min-width: 768px) {
  .icon {
    width: 24px;
    height: 29px;
  }
}

.span {
  font-size: 9px;
}
@media (min-width: 768px) {
  .span {
    font-size: 15px;
  }
}
