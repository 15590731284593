.h3 {
  font-size: 30px;
  font-family: "Hacen Algeria Bd";
  text-align: center;
  margin-bottom: 40px !important;
  color: var(--dark-red-color);
  margin-bottom: 50px !important;
}
@media (min-width: 992px) {
  .h3 {
    margin-bottom: 100px !important;
    font-size: 70px;
  }
}

.container {
  padding: 0 1.1rem;
}
@media (min-width: 375px) {
  .container {
    padding: 0 2.8rem;
  }
}
@media (min-width: 425px) {
  .container {
    padding: 0 4.3rem;
  }
}

.col {
  border: 1px solid var(--dark-black-color);
  width: 146px;
  height: 105px;
}
@media (min-width: 992px) {
  .col {
    width: 313px;
    height: 214px;
  }
}

.absolute {
  position: absolute;
  top: -34px;
  left: 50%;
  transform: translateX(-50%);
  background: #ffffff 0% 0% no-repeat padding-box;
  padding: 0 10px !important;
}
@media (min-width: 992px) {
  .absolute {
    top: -43px;
  }
}

.icon {
  width: 51px;
  height: 51px;
}
@media (min-width: 992px) {
  .icon {
    width: 110px;
    height: 110px;
  }
}

.p {
  font-size: 13px;
  font-family: "Hacen Algeria Bd";
  text-align: center;
}
@media (min-width: 1024px) {
  .p {
    font-size: 25px;
  }
}

.details {
  font-size: 10px;
  margin-bottom: 5px;
  cursor: pointer;
}

.details_box {
  display: flex;
  flex-direction: column-reverse;
  gap: 50px;
  max-width: 334.4px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 20px;
  background: var(--dark-black-color);
  color: #ffffff;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 3px;
  z-index: 20;
}
@media (min-width: 992px) {
  .details_box {
    flex-direction: row;
    max-width: 953px;
  }
}

.col_left {
  width: 100%;
}
@media (min-width: 992px) {
  .col_left {
    width: 500px;
    padding: 30px;
  }
}

.col_right_image {
  width: 100%;
  margin: auto;
  object-fit: cover;
}
@media (min-width: 992px) {
  .col_right_image {
    width: 363px;
    margin: initial;
  }
}

.title {
  font-family: "Hacen Algeria Bd";
  font-size: 30px;
  margin-bottom: 30px;
}

.description {
  font-size: 15px;
  margin-left: 8px;
  margin-bottom: 30px;
}
@media (min-width: 768px) {
  .description {
    font-size: 22px;
  }
}

.ul {
  font-size: 13px;
  margin-bottom: 30px;
  margin-left: -8px;
}
@media (min-width: 992px) {
  .ul {
    font-size: 22px;
  }
}

.li {
  list-style-image: url("../assets/images/Rectangle\ 10220.svg");
  padding-left: 11px;
  margin-bottom: 16px;
}

.button {
  background: var(--dark-red-color) !important;
  width: 159px;
  height: 48px;
  font-size: 12px;
  border-radius: 3px;
  margin-left: 16px;
}
@media (min-width: 992px) {
  .button {
    width: 213px;
    height: 58px;
    font-size: 20px;
  }
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 10;
}

.row2 {
  padding: 0 32px !important;
  margin-top: 180px !important;
}
@media (min-width: 1440px) {
  .row2 {
    padding: 0 406px !important;
  }
}

.services_icons {
  width: 40px;
  height: 40px;
}
@media (min-width: 768px) {
  .services_icons {
    width: 109px;
    height: 109px;
  }
}

.h4 {
  font-size: 13px;
  font-family: "Hacen Algeria Bd";
}
@media (min-width: 768px) {
  .h4 {
    font-size: 28px;
  }
}

.p2 {
  font-size: 12px;
}
@media (min-width: 768px) {
  .p2 {
    font-size: 22px;
  }
}

.bg_pink {
  z-index: -1;
}
